<template>
  <div>
    <b-card v-if="filteredClients.length > 0">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="mt-1"
          @submit.prevent="handleSubmit(updateQuotation)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-alert
                v-model="showMessage"
                dismissible
                :variant="messageType"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span
                    class="ml-25"
                    v-html="message"
                  />
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  بيانات العميل
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: client -->
              <validation-provider
                #default="validationContext"
                vid="client"
                name="client"
                rules="required"
              >
                <b-form-group
                  label="العميل *"
                  label-for="client"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="quotationData.client_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="filteredClients"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="client"
                    @input="clientChanged"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: city -->
              <validation-provider
                v-if="cities.length > 0"
                #default="validationContext"
                vid="city_id"
                name="city_id"
                rules="required"
              >
                <b-form-group
                  label="المدينة *"
                  label-for="city_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="quotationData.city_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="cities"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="city_id"
                    @input="cityChanged"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: city -->
              <validation-provider
                v-if="evaluation_purpose.length > 0"
                #default="validationContext"
                vid="evaluation_purpose"
                name="evaluation_purpose"
                rules="required"
              >
                <b-form-group
                  label="الغرض من التقييم *"
                  label-for="evaluation_purpose"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="quotationData.evaluation_purpose"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="evaluation_purpose"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="evaluation_purpose"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: city -->
              <validation-provider
                v-if="valuation_basis.length > 0"
                #default="validationContext"
                vid="valuation_basis"
                name="valuation_basis"
                rules="required"
              >
                <b-form-group
                  label="أساس القيمة *"
                  label-for="valuation_basis"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="quotationData.valuation_basis"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="valuation_basis"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="valuation_basis"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="HomeIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  بيانات العقارات
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-sm"
                    variant="primary"
                    @click="addItem"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>إضافة عقار</span>
                  </b-button>
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
          </b-row>
          <b-row
            v-for="(item, index) in quotationData.costs"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <!-- Field: property_classification_id -->
              <validation-provider
                #default="validationContext"
                vid="property_classification_id"
                name="property_classification_id"
                rules="required"
              >
                <b-form-group
                  label="تصنيف العقار *"
                  label-for="property_classification_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="item.property_classification_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="classifications"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="property_classification_id"
                    @input="propertyClassificationChanged(item, index)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <!-- Field: property_type_id -->
              <validation-provider
                #default="validationContext"
                vid="property_type_id"
                name="property_type_id"
                rules="required"
              >
                <b-form-group
                  label="نوع العقار *"
                  label-for="property_type_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="item.property_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="filteredTypes[index]"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="property_type_id"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <!-- Field: instrument_number -->
              <validation-provider
                #default="validationContext"
                vid="instrument_number"
                name="instrument_number"
                rules="required"
              >
                <b-form-group
                  label="رقم الصك *"
                  label-for="instrument_number"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="instrument_number"
                    v-model="item.instrument_number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <!-- Field: area -->
              <validation-provider
                #default="validationContext"
                vid="area"
                name="area"
                rules="required"
              >
                <b-form-group
                  label="المساحة *"
                  label-for="area"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="area"
                    v-model="item.area"
                    type="number"
                    step="0.01"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              lg="4"
            >
              <!-- Field: district -->
              <validation-provider
                #default="validationContext"
                vid="district_id"
                name="district_id"
                rules="required"
              >
                <b-form-group
                  label="الحي *"
                  label-for="district_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="item.district_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="districts"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="district_id"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="2" lg="2">
              <!-- Field: cost -->
              <validation-provider
                #default="validationContext"
                vid="cost"
                name="cost"
                rules="required"
              >
                <b-form-group
                  label="أتعاب التقييم *"
                  label-for="cost"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="cost"
                    v-model="item.cost"
                    type="number"
                    step="0.01"
                    @input="updateGrossCost(item)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="2" lg="2">
              <!-- Field: gross_cost -->
              <validation-provider
                #default="validationContext"
                vid="gross_cost"
                name="gross_cost"
                rules="required"
              >
                <b-form-group
                  label="أتعاب شاملة للقيمة المضافة*"
                  label-for="gross_cost"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="gross_cost"
                    v-model="item.gross_cost"
                    type="number"
                    step="0.01"
                    @input="updateCost(item)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Remove Button -->
            <b-col
              v-if="quotationData.costs.length > 1"
              cols="12"
              md="2"
              lg="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>حذف</span>
              </b-button>
            </b-col>
            <b-col
              v-if="quotationData.cost_type === 'variable'"
              cols="12"
            >
              <hr>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                حفظ التغييرات
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="resetData()"
              >
                إعادة ضبط
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCard, BAlert, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import quotationStore from '../quotationStore';

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin, heightTransition],
  props: {
    quotationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    // Calculate gross_cost for each item in quotationData.costs
    this.quotationData.costs.forEach((item) => {
      item.gross_cost = (item.cost * (1 + this.tax / 100)).toFixed(2);
    });
  },
  methods: {
    updateGrossCost(item) {
      item.gross_cost = (item.cost * (1+ this.tax /100)).toFixed(2);
    },
    updateCost(item) {
      item.cost = (item.gross_cost / (1+ this.tax /100)).toFixed(2);
    },
    propertyClassificationChanged(item, index) {
      this.quotationData.costs[index].property_type_id = null;
      this.filteredTypes[index] = this.types.filter(
        (type) => type.property_classification_id === item.property_classification_id,
      );
    },
    resetData() {
      this.fetchDistricts(this.oldQuotationData.city_id);
      this.$emit('reset-quotation-data');
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('quotation/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    cityChanged(city_id) {
      this.fetchDistricts(city_id);
      this.quotationData.city_id = city_id;
      this.quotationData.costs.map((cost) => {
        cost.district_id = null;
      });
    },
    addItem() {
      this.quotationData.costs.push({
        property_type_id: null,
        property_classification_id: null,
        district_id: null,
        cost: 0,
        gross_cost : 0,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.quotationData.costs.splice(index, 1);
    },
    clientChanged(client) {
      this.quotationData.client_id = client;
    },
    updateQuotation() {
      this.quotationData.total_cost = this.quotationData.costs.reduce(
        (total, cost) => total + (+cost.cost),
        0,
      );
      this.quotationData.estate_count = this.quotationData.costs.length;

      this.$store
        .dispatch('app-quotation/updateQuotation', { quotationData: this.quotationData, id: this.quotationData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/quotation').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    clientChanged(client) {
      this.quotationData.client_id = client;
    },
    statusChanged(status) {
      this.quotationData.status_id = status;
    },
  },

  setup(props) {
    const QUOTATION_STORE_MODULE_NAME = 'quotation';

    // Register quotation
    if (!store.hasModule(QUOTATION_STORE_MODULE_NAME)) store.registerModule(QUOTATION_STORE_MODULE_NAME, quotationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_STORE_MODULE_NAME);
    });
    const clients = ref([]);
    const filteredClients = ref([]);
    const districts = ref([]);
    const districtsData = store
      .dispatch('quotation/fetchDistricts', { id: props.quotationData.city_id })
      .then((response) => {
        const { data } = response.data;
        districts.value = data.map((district) => ({
          label: district.name_ar,
          value: district.id,
        }));
      });
    const res = store.dispatch('quotation/fetchClients')
      .then((response) => {
        const { data } = response.data;
        clients.value = data.map((c) => ({ label: c.name, value: c.id, ...c }));
        filteredClients.value = clients.value
      });

    const cities = ref([]);
    store
      .dispatch('quotation/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      });

    const types = ref([]);
    const filteredTypes = ref([]);
    const classifications = ref([]);
    const oldQuotationData = JSON.parse(JSON.stringify(props.quotationData));
    const tax = ref(0) ;
    store.dispatch('quotation/fetchQuotationOptions').then((response) => {
      const { propertyTypes, propertyClassifications , tax_value } = response.data;
        tax.value = tax_value ;
        types.value = propertyTypes.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
      filteredTypes.value[0] = types.value;
      oldQuotationData.costs.forEach((item, index) => {
        filteredTypes.value[index] = types.value
        item.gross_cost = (item.cost * (1+ tax_value /100)).toFixed(2);
      });
      classifications.value = propertyClassifications.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });

    const statuses = [
      {
        label: 'في الانتظار',
        value: 'في الانتظار',
      },
      {
        label: 'مقبول',
        value: 'مقبول',
      },
      {
        label: 'مرفوض',
        value: 'مرفوض',
      },
    ];
    const resetQuotationData = () => {
      props.quotationData.value = JSON.parse(JSON.stringify(oldQuotationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetQuotationData);
    const valuation_basis = [
      {
        label: 'القيمة السوقية',
        value: 'القيمة السوقية',
      },
      {
        label: 'الإيجار السوقي',
        value: 'الإيجار السوقي',
      },
      {
        label: 'القيمة المنصفة',
        value: 'القيمة المنصفة',
      },
      {
        label: 'القيمة الاستثمارية',
        value: 'القيمة الاستثمارية',
      },
      {
        label: 'القيمة التكاملية',
        value: 'القيمة التكاملية',
      },
      {
        label: 'قيمة التصفية',
        value: 'قيمة التصفية',
      },
      {
        label: 'اسس أخرى للقيمة',
        value: 'اسس أخرى للقيمة',
      },
      {
        label: 'قسري',
        value: 'قسري',
      },
    ];
    const evaluation_purpose = [
      {
        label: "الشراء",
        value: "الشراء",
      },
      {
        label: "البيع",
        value: "البيع",
      },
      {
        label: "تقدير القيمة الايجارية",
        value: "تقدير القيمة الايجارية",
      },
      {
        label: "التأمين",
        value: "التأمين",
      },
      {
        label: "الرهن العقاري",
        value: "الرهن العقاري",
      },
      {
        label: "أغراض محاسبية",
        value: "أغراض محاسبية",
      },
      {
        label: "التمويل",
        value: "التمويل",
      },
      {
        label: "النزاعات والتقاضى",
        value: "النزاعات والتقاضى",
      },
      {
        label: "نزع الملكية",
        value: "نزع الملكية",
      },
      {
        label: "الضرائب",
        value: "الضرائب",
      },
      {
        label: "أغراض داخلية",
        value: "أغراض داخلية",
      },
      {
        label: "البيع في المزاد العلني",
        value: "البيع في المزاد العلني",
      },
    ];
    return {
      oldQuotationData,
      types,
      tax,
      filteredTypes,
      classifications,
      cities,      
      valuation_basis,
      evaluation_purpose,
      districts,
      filteredClients,
      refFormObserver,
      getValidationState,
      resetForm,
      clients,
      statuses,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
